import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import BusquedaGrid from "./busquedaGrid";
import { Button } from "@mui/material";
import { Column } from "@material-table/core";

export default function Busqueda({
  funcionCerrar,
  collection,
  where,
  order,
  otrosParametros,
  listadoManual,
  permitirNuevoRegistro,
  columnas,
}: {
  funcionCerrar: (registro?: any, nuevoRegistro?: boolean) => void;
  collection: string;
  where?: string;
  order?: string;
  otrosParametros?: object;
  listadoManual?: any[];
  permitirNuevoRegistro?: string;
  columnas?: Column<any>[];
}) {
  return (
    <Modal isOpen={true} zIndex={2000} size="xl">
      <ModalBody className="modalBody" style={{ padding: "0px" }}>
        <BusquedaGrid
          onClick={funcionCerrar}
          collection={collection}
          where={where}
          order={order}
          otrosParametros={otrosParametros}
          listadoManual={listadoManual}
          columnas={columnas}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={() => funcionCerrar()}
          variant="contained"
          color="secondary"
        >
          Cancelar
        </Button>

        {permitirNuevoRegistro && (
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => funcionCerrar(null, true)}
            variant="contained"
            color="primary"
          >
            Nuevo
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
