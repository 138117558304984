import React, { Fragment, useContext } from "react";

import { useQuery } from "react-query";
import { queryClient } from "../../..";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Badge, Button, IconButton } from "@material-ui/core";

import {
  Delete,
  Notifications,
  NotificationsActive,
  Close,
} from "@mui/icons-material";

import classes from "./Notificaciones.module.css";
import { authContext } from "../../MainComponent";
import { Consultar, Eliminar } from "../../common/server/funcionesServidor";
import { INotificacion } from "../../../AppInterfaces";

/**muestra el listado de notificaciones */
export default function Notificaciones({ usuarioId }: { usuarioId: number }) {
  const collection = "Notificacions";
  const {
    onActualizarLoginState,
    companiaSeleccionada: { id: companiaId },
  } = useContext(authContext);

  const { data } = useQuery(
    collection,
    async () => {
      const notificaciones = await Consultar<INotificacion>(
        `api/${collection}/consultar`,
        undefined,
        undefined,
        {
          where: `idUsuario = ${usuarioId} and companiaId = ${companiaId} and ((actualizarLoginState = 1) or (actualizarCache = 1) or (emailEnviado = 1))`,
          order: "id ASC",
        }
      );

      let actualizarLoginState = false,
        actualizarCache = false;

      for (let index = 0; index < notificaciones.length; index++) {
        if (
          notificaciones[index].actualizarLoginState ||
          notificaciones[index].actualizarCache
        ) {
          if (notificaciones[index].actualizarLoginState) {
            actualizarLoginState = true;
          }
          if (notificaciones[index].actualizarCache) {
            actualizarCache = true;
          }

          await Eliminar(`api/${collection}`, notificaciones[index].id, true);
          notificaciones.splice(index, 1);
        }
      }

      if (actualizarLoginState) onActualizarLoginState();
      if (actualizarCache) queryClient.invalidateQueries();

      return notificaciones.filter(
        (notificacion) =>
          !notificacion.actualizarLoginState && !notificacion.actualizarCache
      );
    },
    {
      refetchInterval: 1800000,
    }
  );

  const descartarNotificaciones = async (idNotificacion?: number) => {
    if (!data) return;

    if (idNotificacion)
      await Eliminar(`api/${collection}`, idNotificacion, true);
    else {
      for (let index = 0; index < data.length; index++) {
        await Eliminar(`api/${collection}`, data[index].id, true);
      }
    }

    queryClient.invalidateQueries(collection);
  };

  return !data ? null : (
    <UncontrolledDropdown>
      <DropdownToggle tag="span">
        <div className={classes.campana}>
          {data.length === 0 ? (
            <Notifications className={classes.iconoNotificacion} />
          ) : (
            <Badge
              badgeContent={data.length + "+"}
              color="secondary"
              invisible={!data.length}
            >
              <NotificationsActive className={classes.iconoNotificacion} />
            </Badge>
          )}
        </div>
      </DropdownToggle>

      <DropdownMenu align="end" className={classes.container}>
        <DropdownItem header>
          <strong style={{ fontSize: "1.3rem" }}>Notificaciones</strong>
          {data.length !== 0 && (
            <IconButton
              size="medium"
              color="secondary"
              style={{ float: "right" }}
              onClick={() => descartarNotificaciones()}
            >
              <Close />
            </IconButton>
          )}
        </DropdownItem>

        <DropdownItem divider />

        {data.length === 0 && <DropdownItem>Sin Notificaciones</DropdownItem>}

        {data.map((alerta) => (
          <Fragment key={alerta.id}>
            <DropdownItem
              className={classes.dropdownitem}
              onClick={() => descartarNotificaciones(alerta.id)}
            >
              <p className={classes.desde}>{alerta.desde}</p>
              <p style={{ whiteSpace: "normal" }}>{alerta.mensaje}</p>
            </DropdownItem>
            <DropdownItem divider />
          </Fragment>
        ))}

        {data.length !== 0 && (
          <Button
            className={classes.descartarTodos}
            color="secondary"
            onClick={() => descartarNotificaciones()}
          >
            <Delete />
            Descartar todos
          </Button>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
