import React from "react";
import { Hidden, Typography } from "@material-ui/core";

/**
 * presenta identificación de la compañía
 * @param RutaLogoCompania logo a presentar, si esta vacio presenta nombre de compañía
 * @param RutaIconoCompania icono a presentar, si esta vacio presenta nombre de compañía
 * @param nombreCompania nombre de la compañía a presentar en caso de no pasar logo o icono
 */
export default function Logo({
  RutaLogoCompania,
  RutaIconoCompania,
  nombreCompania,
}: {
  RutaLogoCompania: string;
  RutaIconoCompania: string;
  nombreCompania: string;
}) {
  return (
    <>
      {RutaLogoCompania ? (
        <Hidden xsDown>
          <img
            style={{ maxHeight: "5rem", verticalAlign: "baseline" }}
            src={"data:image/png;base64," + RutaLogoCompania}
            alt="Logo Compañía"
          />
        </Hidden>
      ) : (
        <Hidden xsDown>
          <Typography
            component="h2"
            variant="h4"
            style={{ color: "black", lineHeight: "5rem", whiteSpace: "nowrap" }}
          >
            <strong>{nombreCompania}</strong>
          </Typography>
        </Hidden>
      )}

      {RutaIconoCompania ? (
        <Hidden smUp>
          <img
            style={{ maxHeight: "5rem", verticalAlign: "baseline" }}
            src={"data:image/png;base64," + RutaIconoCompania}
            alt="Icono Compañía"
          />
        </Hidden>
      ) : (
        <Hidden smUp>
          <Typography
            component="h2"
            variant="h5"
            style={{
              color: "black",
              lineHeight: "5.5rem",
              whiteSpace: "nowrap",
            }}
          >
            <strong>{nombreCompania}</strong>
          </Typography>
        </Hidden>
      )}
    </>
  );
}
