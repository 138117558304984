import { Column, Options, Localization } from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import { Grid } from "@mui/material";
import {
  FormatearFecha,
  convertirTextoAFecha,
  ExportarPDF,
  NombreMes,
  FormatearNumero,
  formatNumberDecimals,
} from "../funciones/funciones";
import { TIPO_NOMINA_LABEL } from "../../constants";
import { getAbbreviatura } from "../contantes/unidadesMedida";

export const options = (
  nombreFile?: string,
  titulo?: string
): Options<any> => ({
  headerStyle: {
    color: "#800000",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  rowStyle: {
    fontSize: "1rem",
  },
  grouping: false,
  filtering: true,
  paging: false,
  pageSizeOptions: [5, 10, 20, 50],
  exportMenu: [
    {
      label: "Exportar PDF",
      exportFunc: (cols, datas) =>
        ExportarPDF(cols, datas, nombreFile ? nombreFile : "PDFFile", titulo),
    },
    {
      label: "Exportar CSV",
      exportFunc: (cols, datas) =>
        ExportCsv(cols, datas, nombreFile ? nombreFile : "CSVFile"),
    },
  ],
  search: true,
  showTitle: true,
  tableLayout: "auto",
  columnsButton: true,
  sorting: true,
  draggable: true,
});

export const localization: Localization = {
  grouping: {
    placeholder: "Arrastra aquí las cabezeras que deseas agrupar.",
  },
  toolbar: {
    addRemoveColumns: "Agregar o remover columnas",
    showColumnsTitle: "Mostrar / Ocultar columnas",
    showColumnsAriaLabel: "Mostrar / Ocultar columnas",
    searchTooltip: "Buscar",
    searchPlaceholder: "Buscar...",
    exportTitle: "Exportar",
    exportAriaLabel: "Exportar",
  },
  header: {
    actions: "Acciones",
  },
  body: {
    emptyDataSourceMessage: "No hay información que presentar",
  },
};

export const cabezeras = (
  collection: string,
  utilizaCentroCosto: boolean = false
): Column<any>[] => {
  switch (collection) {
    case "AGlobalEventos":
      return [
        {
          type: "date",
          title: "Fecha",
          field: "fecha",
          render: (rowData) => {
            const fecha = new Date(rowData.fecha);
            return fecha.toLocaleString();
          },
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Documento",
          field: "tipoDocumento",
        },
        {
          type: "string",
          title: "Evento",
          field: "tipoEvento",
        },
        {
          type: "string",
          title: "Identificación",
          field: "documento",
        },
        {
          type: "string",
          title: "Usuario",
          field: "usuario",
        },
      ];

    case "ActivoFijoActivos":
      return [
        {
          type: "string",
          title: "Referencia",
          field: "referencia",
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Código de Barra",
          field: "codigoBarra",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Categoría",
          field: "categoriaNombre",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Tasa Depr.",
          field: "categoriaPorcentaje",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Cuenta Contable Activo",
          field: "categoriaCuentaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Departamento",
          field: "departamentoNombre",
        },
        {
          type: "string",
          title: "Cuenta Contable Gasto",
          field: "departamentoCuentaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Grupo",
          field: "grupoNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Marca",
          field: "marca",
          hidden: true,
        },
        {
          type: "string",
          title: "Modelo",
          field: "modelo",
          hidden: true,
        },
        {
          type: "string",
          title: "Serie",
          field: "serie",
          hidden: true,
        },
        {
          type: "string",
          title: "Factura",
          field: "factura",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Costo",
          field: "costo",
          hidden: true,
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidor",
          hidden: true,
        },
        {
          type: "date",
          title: "Fecha Compra",
          field: "fechaCompra",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaCompra),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "date",
          title: "Fecha Inicio Depreciacion",
          field: "fechaInicioDepreciacion",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaInicioDepreciacion),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "numeric",
          title: "Depr. Acum. Inicial",
          field: "depreciacionAcumuladaInicial",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Valor en Libro",
          field: "valorLibro",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Valor Residual",
          field: "valorResidual",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Depr. Mensual",
          field: "depreciacionMensual",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Depr. Acumulada",
          field: "depreciacionAcumulada",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "ActivoFijoCategoria":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "categoriaNombre",
        },
        {
          type: "string",
          title: "Porcentaje",
          field: "categoriaPorcentaje",
        },
        {
          type: "string",
          title: "Cuenta Contable",
          field: "cuentaNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
      ];

    case "ActivoFijoDepartamentos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Cuenta Contable",
          field: "cuentaNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "ActivoFijoDepreciacions":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
      ];

    case "ActivoFijoDepreciacionDetalle":
      return [
        {
          type: "string",
          title: "Referencia",
          field: "activoReferencia",
        },
        {
          type: "string",
          title: "Nombre",
          field: "activoNombre",
        },
        {
          type: "date",
          title: "Fecha de Compra",
          field: "activoFechaCompra",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.activoFechaCompra),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.activoCosto),
          title: "Costo Adquisición",
          field: "activoCosto",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.depreciacionAcumInicio),
          title: "Depreciación Acum. Inicial",
          field: "depreciacionAcumInicio",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Depreciación Actual",
          field: "monto",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.depreciacionAcumFin),
          title: "Depreciación Acumulada",
          field: "depreciacionAcumFin",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.valorLibro),
          title: "Valor en Libro",
          field: "valorLibro",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
      ];

    case "ActivoFijoGrupos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "ActivoFijoResponsable":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "AGlobalMedioPagos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
      ];

    case "AGlobalTipoBienServicios":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo606",
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
      ];

    case "AGlobalTipoAnulacion":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
      ];

    case "AGlobalTipoComprobantes":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
      ];

    case "AGlobalTipoIngresos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
      ];

    case "AGlobalTipoITBIS":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
      ];

    case "AGlobalTipoRetencionIsrs":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Porcentaje",
          field: "porcentaje",
        },
      ];

    case "AGlobalTipoRetencionItbis":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Porcentaje",
          field: "porcentaje",
        },
      ];

    case "BancoCargos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoCheques":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "suplidorRncCedula",
        },
        {
          type: "string",
          title: "Cuenta CxP",
          field: "suplidorNombre",
        },
        {
          type: "string",
          title: "Destinatario",
          field: "destinatario",
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Moneda Aplicar",
          field: "monedaAplicarNombre",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto",
          field: "monto",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetItbis),
          title: "Retenido ITBIS",
          field: "montoRetItbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetIsr),
          title: "Retenido ISR",
          field: "montoRetIsr",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Solicitud",
          field: "solicitudCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación",
          field: "conciliacionCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Prestamo",
          field: "prestamoCodigo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoChequesOtros":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Destinatario",
          field: "destinatario",
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Moneda Aplicar",
          field: "monedaAplicarNombre",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Solicitud",
          field: "solicitudCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación",
          field: "conciliacionCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Prestamo",
          field: "prestamoCodigo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoChequeConfiguracionImpresion":
      return [
        {
          type: "string",
          title: "Banco",
          field: "cuentaNombre",
          render: (rowData) =>
            rowData.cuentaCuentaBancaria + " - " + rowData.cuentaNombre,
        },
        {
          type: "string",
          title: "Tamaño de Papel",
          field: "tamanoPapel",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            rowData.tamanoPapel === 0 ? "8.5in x 5.5in" : "8.5in x 11in",
        },
        {
          type: "numeric",
          title: "Destinatario X",
          field: "destinatarioX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Destinatario Y",
          field: "destinatarioY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Monto X",
          field: "montoX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Monto Y",
          field: "montoY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Monto en Letra X",
          field: "montoLetraX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Monto en Letra Y",
          field: "montoLetraY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Número de Cheque X",
          field: "numeroChequeX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Número de Cheque Y",
          field: "numeroChequeY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Día X",
          field: "diaX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Día Y",
          field: "diaY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Mes X",
          field: "mesX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Mes Y",
          field: "mesY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Año X",
          field: "anoX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Año Y",
          field: "anoY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Concepto Fecha X",
          field: "conceptoFechaX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Concepto Fecha Y",
          field: "conceptoFechaY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Concepto Observación X",
          field: "conceptoObservacionX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Concepto Observación Y",
          field: "conceptoObservacionY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Concepto Monto X",
          field: "conceptoMontoX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Concepto Monto Y",
          field: "conceptoMontoY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuenta X",
          field: "cuentaX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuenta Y",
          field: "cuentaY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuenta Nombre X",
          field: "cuentaNombreX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuenta Nombre Y",
          field: "cuentaNombreY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuenta Débito X",
          field: "cuentaDebitoX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuenta Débito Y",
          field: "cuentaDebitoY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuenta Crédito X",
          field: "cuentaCreditoX",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuenta Crédito Y",
          field: "cuentaCreditoY",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
      ];

    case "BancoConciliacions":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceInicial),
          title: "Bal. Inicio",
          field: "balanceInicial",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ckConciliado),
          title: "CK Concil.",
          field: "ckConciliado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ckTransito),
          title: "CK Trans.",
          field: "ckTransito",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tbsConciliado),
          title: "TBS Concil.",
          field: "tbsConciliado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tbsTransito),
          title: "TBS Trans.",
          field: "tbsTransito",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tbeConciliado),
          title: "TBE Concil.",
          field: "tbeConciliado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tbeTransito),
          title: "TBE Trans.",
          field: "tbeTransito",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.cbConciliado),
          title: "CB Concil.",
          field: "cbConciliado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.cbTransito),
          title: "CB Trans.",
          field: "cbTransito",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.dpConciliado),
          title: "DP Concil.",
          field: "dpConciliado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.dpTransito),
          title: "DP Trans.",
          field: "dpTransito",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.crConciliado),
          title: "CR Concil.",
          field: "crConciliado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.crTransito),
          title: "CR Trans.",
          field: "crTransito",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceFinal),
          title: "Bal. Conciliado",
          field: "balanceFinal",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceBanco),
          title: "Bal. Banco",
          field: "balanceBanco",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceContable),
          title: "Bal. Contable",
          field: "balanceContable",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "BancoCuenta":
      return [
        {
          type: "string",
          title: "RNC",
          field: "rnc",
        },
        {
          type: "string",
          title: "Cuenta Bancaria",
          field: "cuentaBancaria",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Teléfono 1",
          field: "telefono1",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Teléfono 2",
          field: "telefono2",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 3",
          field: "telefono3",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 4",
          field: "telefono4",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Cta. Contable",
          field: "cuentaNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "boolean",
          title: "No Sobregiro",
          field: "noPermiteSobregiro",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "BancoCreditos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoDepositos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta Bancaria",
          field: "bancoCuentaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoSolicituds":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto",
          field: "monto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetItbis),
          title: "Retenido ITBIS",
          field: "montoRetItbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetIsr),
          title: "Retenido ISR",
          field: "montoRetIsr",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "string",
          title: "Autorizado Por",
          field: "autorizadoPor",
        },
        {
          type: "string",
          title: "Rechazada Por",
          field: "rechazadoPor",
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoTransferencia":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "numeric",
          title: "No. Referencia",
          field: "numeroReferencica",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "suplidorRncCedula",
        },
        {
          type: "string",
          title: "Cuenta CxP",
          field: "suplidorNombre",
        },
        {
          type: "string",
          title: "Destinatario",
          field: "destinatario",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto",
          field: "monto",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetItbis),
          title: "Retenido ITBIS",
          field: "montoRetItbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetIsr),
          title: "Retenido ISR",
          field: "montoRetIsr",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Solicitud",
          field: "solicitudCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación Origen",
          field: "conciliacionCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación Destino",
          field: "conciliacionAplicarCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Prestamo",
          field: "prestamoCodigo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoTransferenciaCuenta":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Solicitud",
          field: "solicitudCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación Origen",
          field: "conciliacionCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación Destino",
          field: "conciliacionAplicarCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Prestamo",
          field: "prestamoCodigo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoTransferenciaOtros":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "bancoCuentaCuentaBancaria",
        },
        {
          type: "string",
          title: "Nombre",
          field: "bancoCuentaNombre",
        },
        {
          type: "string",
          title: "Destinatario",
          field: "destinatario",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Solicitud",
          field: "solicitudCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación Origen",
          field: "conciliacionCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación Destino",
          field: "conciliacionAplicarCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Prestamo",
          field: "prestamoCodigo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "BancoReportesresumenPagos":
      return [
        {
          type: "string",
          title: "Tipo",
          field: "tipo",
        },
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta Origen",
          field: "bancoCuentaCuentaBancaria",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Banco Origen",
          field: "bancoCuentaNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "RNC",
          field: "suplidorRncCedula",
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidorNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Cuenta Entrante",
          field: "bancoCuentaAplicarCuentaBancaria",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Banco Entrante",
          field: "bancoCuentaAplicarNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Moneda Saliente",
          field: "monedaNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Moneda Entrante",
          field: "monedaAplicarNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto",
          field: "monto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetItbis),
          title: "Retenido ITBIS",
          field: "montoRetItbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetIsr),
          title: "Retenido ISR",
          field: "montoRetIsr",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Conciliación",
          field: "conciliacionCodigo",
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "Compania":
      return [
        {
          type: "string",
          title: "RNC",
          field: "rnc",
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "numeric",
          title: "Selectivo %",
          field: "selectivoPor",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Propina %",
          field: "propinaPor",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Teléfono",
          field: "telefono",
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "E-Mail",
          field: "eMail",
        },
        {
          type: "boolean",
          title: "Usa Centro Costo",
          field: "utilizaCentroCosto",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
      ];

    case "CompaniaMonedas":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
      ];

    case "ContabilidadCatalogos":
      return [
        {
          type: "string",
          title: "Cuenta",
          field: "cuenta",
          sorting: true,
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Cuenta Control",
          field: "cuentaControl",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Nombre Cuenta Control",
          field: "cuentaControlNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Tipo Cuenta",
          field: "tipoCuentaId",
          lookup: {
            1: "ACTIVO",
            2: "PASIVO",
            3: "CAPITAL",
            4: "INGRESO",
            5: "COSTO",
            6: "GASTO",
          },
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Origen",
          field: "origen",
          lookup: { 1: "DEBITO", 2: "CREDITO" },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Es Control",
          field: "isControl",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "ContabilidadCentroCostos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
      ];

    case "ContabilidadCentroCostoProyectos":
      return [
        {
          type: "string",
          title: "Proyecto",
          field: "nombre",
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
        },
      ];

    case "ContabilidadCentroCostoSubProyectos":
      return [
        {
          type: "string",
          title: "Sub-Proyecto",
          field: "nombre",
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
      ];

    case "ContabilidadEjercicioContableCerrados":
      return [
        {
          type: "string",
          title: "Año",
          field: "ano",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Mes",
          field: "mes",
          lookup: {
            1: "Enero",
            2: "Febrero",
            3: "Marzo",
            4: "Abril",
            5: "Mayo",
            6: "Junio",
            7: "Julio",
            8: "Agosto",
            9: "Septiembre",
            10: "Octubre",
            11: "Noviembre",
            12: "Diciembre",
          },
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
      ];

    case "ContabilidadEntradaDiarioManuals":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Concepto",
          field: "concepto",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Destinatario",
          field: "destinatario",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "ContabilidadMayorGeneral":
      return [
        {
          type: "string",
          title: "Cuenta",
          field: "cuenta",
        },
        {
          type: "string",
          title: "Cuenta",
          field: "cuentaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceAnterior),
          title: "Balance Anterior",
          field: "balanceAnterior",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.debito),
          title: "Débito",
          field: "debito",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.credito),
          title: "Crédito",
          field: "credito",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceActual),
          title: "Balance Actual",
          field: "balanceActual",
        },
      ];

    case "ContabilidadPeriodoContableAbiertos":
      return [
        {
          type: "string",
          title: "Año",
          field: "ano",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Mes",
          field: "mes",
          lookup: {
            1: "Enero",
            2: "Febrero",
            3: "Marzo",
            4: "Abril",
            5: "Mayo",
            6: "Junio",
            7: "Julio",
            8: "Agosto",
            9: "Septiembre",
            10: "Octubre",
            11: "Noviembre",
            12: "Diciembre",
          },
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
      ];

    case "ContabilidadPresupuesto":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Fecha Inicio",
          field: "mesInicio",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            NombreMes(rowData.mesInicio) + " - " + rowData.anoInicio,
        },
        {
          type: "string",
          title: "Fecha Fin",
          field: "mesFin",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            NombreMes(rowData.mesFin) + " - " + rowData.anoFin,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "ContabilidadRep606":
      return [
        {
          type: "string",
          title: "Tipo",
          field: "tipo",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidor",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "suplidorRncCedula",
        },
        {
          type: "numeric",
          title: "Identificador",
          field: "identificador",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Tipo Bien / Servicio",
          field: "tipoBienServicio",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "NCF Modificado",
          field: "ncfModificado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Año / Mes",
          field: "anoMes",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Día",
          field: "dia",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Año / Mes Pago",
          field: "anoMesPago",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Día Pago",
          field: "diaPago",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.netoServicio),
          title: "Monto Servicios",
          field: "netoServicio",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.netoBien),
          title: "Monto Bienes",
          field: "netoBien",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.netoFacturado),
          title: "Monto Facturado",
          field: "netoFacturado",
          cellStyle: { minWidth: 160 },
          headerStyle: { minWidth: 160 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbisFacturado),
          title: "ITBIS Facturado",
          field: "itbisFacturado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.retencionItbis),
          title: "ITBIS Retenido",
          field: "retencionItbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbisArt349),
          title: "ITBIS Art. 349",
          field: "itbisArt349",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbisCosto),
          title: "ITBIS al Costo",
          field: "itbisCosto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS Adelantado",
          field: "itbis",
          cellStyle: { minWidth: 160 },
          headerStyle: { minWidth: 160 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbisCompras),
          title: "ITBIS Per. en Compras",
          field: "itbisCompras",
          cellStyle: { minWidth: 200 },
          headerStyle: { minWidth: 200 },
        },
        {
          type: "string",
          title: "Tipo Retención ISR",
          field: "retencionIsrNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.retencionIsr),
          title: "ISR Retenido",
          field: "retencionIsr",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.isrPercibidoCompras),
          title: "ISR Per. en Compras",
          field: "isrPercibidoCompras",
          cellStyle: { minWidth: 180 },
          headerStyle: { minWidth: 180 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo Consumo",
          field: "selectivo",
          cellStyle: { minWidth: 170 },
          headerStyle: { minWidth: 170 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp. y Tasas",
          field: "otrosImpuestos",
          cellStyle: { minWidth: 170 },
          headerStyle: { minWidth: 170 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina Legal",
          field: "propina",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Forma de Pago",
          field: "formaPago",
          cellStyle: { minWidth: 350 },
          headerStyle: { minWidth: 350 },
        },
      ];

    case "ContabilidadRep607":
      return [
        {
          type: "string",
          title: "Tipo",
          field: "tipo",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Cliente",
          field: "cliente",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "numeric",
          title: "Identificador",
          field: "identificador",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "NCF Modificado",
          field: "ncfModificado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Tipo Ingreso",
          field: "tipoIngreso",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Fecha",
          field: "fecha",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Fecha Retención",
          field: "fechaRetencion",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoFacturado),
          title: "Monto Facturado",
          field: "montoFacturado",
          cellStyle: { minWidth: 160 },
          headerStyle: { minWidth: 160 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbisFacturado),
          title: "ITBIS Facturado",
          field: "itbisFacturado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbisRetenido),
          title: "ITBIS Retenido",
          field: "itbisRetenido",
          cellStyle: { minWidth: 160 },
          headerStyle: { minWidth: 160 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbisPercibido),
          title: "ITBIS Percibido",
          field: "itbisPercibido",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.isrRetenido),
          title: "ISR Retenido",
          field: "isrRetenido",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.isrPercibido),
          title: "ISR Percibido",
          field: "isrPercibido",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Impuestos",
          field: "otrosImpuestos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.efectivo),
          title: "Efectivo",
          field: "efectivo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ckTbDp),
          title: "Cheque / Trans. / Dep.",
          field: "ckTbDp",
          cellStyle: { minWidth: 200 },
          headerStyle: { minWidth: 200 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tarjeta),
          title: "Tarjeta",
          field: "tarjeta",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.credito),
          title: "Crédito",
          field: "credito",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.bonos),
          title: "Bonos",
          field: "bonos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.permuta),
          title: "Permuta",
          field: "permuta",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosPagos),
          title: "Otros Pagos",
          field: "otrosPagos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "ContabilidadRep608":
      return [
        {
          type: "string",
          title: "Tipo",
          field: "tipo",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Cliente",
          field: "cliente",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Fecha",
          field: "fecha",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Tipo de Anulación",
          field: "tipoAnulacion",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
      ];

    case "ContabilidadRep623":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Cliente",
          field: "cliente",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Periodo",
          field: "periodo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Fecha Retenido",
          field: "fecha",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Valor Retenido",
          field: "monto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Número de Referencia",
          field: "documento",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Tipo de Referencia",
          field: "tipoReferencia",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Banco",
          field: "banco",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
      ];

    case "ContabilidadRepIR17":
      return [
        {
          type: "numeric",
          title: "tipoRetencionId",
          field: "tipoRetencionId",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidor",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "suplidorRncCedula",
        },
        {
          type: "string",
          title: "Tipo de Retención",
          field: "tipoRetencionNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoImponible),
          title: "Monto Imponible",
          field: "montoImponible",
          cellStyle: { minWidth: 170 },
          headerStyle: { minWidth: 170 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Valor Retenido",
          field: "monto",
          cellStyle: { minWidth: 170 },
          headerStyle: { minWidth: 170 },
        },
      ];

    case "ContabilidadRepIR17Resumen":
      return [
        {
          type: "string",
          title: "Tipo de Retención",
          field: "nombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoImponible),
          title: "Monto Imponible",
          field: "montoImponible",
          cellStyle: { minWidth: 170 },
          headerStyle: { minWidth: 170 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Valor Retenido",
          field: "monto",
          cellStyle: { minWidth: 170 },
          headerStyle: { minWidth: 170 },
        },
      ];

    case "ContabilidadRepBalanzaComprobacion":
      return [
        { type: "string", title: "control", field: "control", hidden: true },
        {
          type: "string",
          title: "Cuenta",
          field: "cuenta",
        },
        {
          type: "string",
          title: "Nombre",
          field: "cuentaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.auxiliares),
          title: "Auxiliares",
          field: "auxiliares",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.debito),
          title: "Débito",
          field: "debito",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.credito),
          title: "Crédito",
          field: "credito",
        },
      ];

    case "ContabilidadRepEntradaDiario":
      return [
        {
          type: "string",
          title: "Tipo de Documento",
          field: "tipo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Concepto",
          field: "concepto",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Destinatario",
          field: "destinatario",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
      ];

    case "ContabilidadRepMovimientoCuenta":
      return [
        {
          type: "string",
          title: "Tipo",
          field: "tipo",
          cellStyle: { minWidth: 50 },
          headerStyle: { minWidth: 50 },
        },
        {
          type: "string",
          title: "Doc.",
          field: "documento",
          cellStyle: { minWidth: 50 },
          headerStyle: { minWidth: 50 },
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Concepto",
          field: "concepto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Destinatario",
          field: "destinatario",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceAnterior),
          title: "Bal. Ant.",
          field: "balanceAnterior",
          cellStyle: { minWidth: 120 },
          headerStyle: { minWidth: 120 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.debito),
          title: "Débito",
          field: "debito",
          cellStyle: { minWidth: 120 },
          headerStyle: { minWidth: 120 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.credito),
          title: "Crédito",
          field: "credito",
          cellStyle: { minWidth: 120 },
          headerStyle: { minWidth: 120 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balancePosterior),
          title: "Balance",
          field: "balancePosterior",
          cellStyle: { minWidth: 120 },
          headerStyle: { minWidth: 120 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
      ];

    case "ContabilidadRepResultado":
      return [
        {
          type: "string",
          title: "Cuenta",
          field: "cuenta",
        },
        {
          type: "string",
          title: "Cuenta",
          field: "cuentaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balance),
          title: "Auxiliar",
          field: "balance",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceTotal),
          title: "Balance",
          field: "balanceTotal",
        },
      ];

    case "ContabilidadRepSituacion":
      return [
        {
          type: "string",
          title: "Cuenta",
          field: "cuenta",
        },
        {
          type: "string",
          title: "Cuenta",
          field: "cuentaNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balance),
          title: "Auxiliar",
          field: "balance",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.balanceTotal),
          title: "Balance",
          field: "balanceTotal",
        },
      ];

    case "CxCClientes":
      return [
        {
          type: "string",
          title: "RNC/Cédula",
          field: "rncCedula",
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Nombre Comercial",
          field: "nombreComercial",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Crédito Días",
          field: "condicionPago",
          cellStyle: { minWidth: 80 },
          headerStyle: { minWidth: 80 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.limiteCredito),
          title: "Crédito $",
          field: "limiteCredito",
          cellStyle: { minWidth: 80 },
          headerStyle: { minWidth: 80 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 1",
          field: "telefono1",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Teléfono 2",
          field: "telefono2",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 3",
          field: "telefono3",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 4",
          field: "telefono4",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "E-Mail",
          field: "eMail",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Contacto",
          field: "contacto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Grupo",
          field: "grupoNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Vendedor",
          field: "vendedorNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Comprobante",
          field: "tipoComprobanteNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "CxCClienteLocalidades":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
        },
      ];

    case "CxCClienteGrupos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Cuenta CxC",
          field: "cuentaCxCNombre",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "CxCCotizacions":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "date",
          title: "Vencimiento",
          field: "fechaVence",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaVence),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Destinatario",
          field: "clienteDestinatario",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Dirección",
          field: "clienteDireccion",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Vendedor",
          field: "vendedorNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.bruto),
          title: "Bruto",
          field: "bruto",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.descuento),
          title: "Descuento",
          field: "descuento",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Neto",
          field: "neto",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp.",
          field: "otrosImpuestos",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Factura",
          field: "facturaCodigo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Crédito",
          field: "isCredito",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "CxCCotizacionEstados":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "CxCCuadresCaja":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha Inicio",
          field: "fechaInicio",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return FormatearFecha(
              convertirTextoAFecha(rowData.fechaInicio),
              "DD-MM-YYYY"
            );
          },
        },
        {
          type: "date",
          title: "Fecha Cierre",
          field: "fechaCierre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return rowData.fechaCierre
              ? FormatearFecha(
                  convertirTextoAFecha(rowData.fechaCierre),
                  "DD-MM-YYYY"
                )
              : undefined;
          },
        },
        {
          type: "string",
          title: "Usuario",
          field: "usuarioNombre",
        },
        {
          type: "string",
          title: "Supervisor",
          field: "usuarioSupervisorNombre",
        },
        {
          type: "numeric",
          title: "NOZ",
          field: "NOZ",
          hidden: true,
        },
        {
          type: "string",
          title: "Notas",
          field: "notas",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Cerrado",
          field: "isCerrado",
        },
      ];

    case "CxCCuadreCajaDistPagos":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Tipo",
          field: "medioPagoNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoCredito),
          title: "Crédito",
          field: "montoCredito",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoContado),
          title: "Contado",
          field: "montoContado",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoSalida),
          title: "Salida",
          field: "montoSalida",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoTotal),
          title: "Total",
          field: "montoTotal",
        },
      ];

    case "CxCCuadreCajaCreditosClientes":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
      ];

    case "CxCCuadreCajaDescuentosDevoluciones":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
      ];

    case "CxCCuadreCajaListaRecibos":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Recibo",
          field: "codigo",
        },
        {
          type: "string",
          title: "Tipo",
          field: "medioPagoNombre",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRecibido),
          title: "Monto Recibido",
          field: "montoRecibido",
        },
      ];

    case "CxCCuadreCajaListaRecibosManual":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Recibo",
          field: "codigo",
        },
        {
          type: "string",
          title: "Tipo",
          field: "medioPagoNombre",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteDestinatario",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Monto Recibido",
          field: "total",
        },
      ];

    case "CxCCuadreCajaListaContado":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Factura",
          field: "facturaCodigo",
        },
        {
          type: "string",
          title: "Tipo",
          field: "medioPagoNombre",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto Recibido",
          field: "monto",
        },
      ];

    case "CxCCuadreCajaListaSalidasCaja":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Salida",
          field: "codigo",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Monto",
          field: "total",
        },
      ];

    case "CxCCuadreCajaListaCreditosClientes":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Factura",
          field: "codigo",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Monto",
          field: "total",
        },
      ];

    case "CxCCuadreCajaListaDescuentosDevoluciones":
      return [
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "NC",
          field: "codigo",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Monto",
          field: "total",
        },
      ];

    case "CxCVendedors":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Teléfono",
          field: "telefono",
        },
        {
          type: "string",
          title: "Celular",
          field: "celular",
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "CxCFacturas":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "numeric",
          title: "Cotización",
          field: "cotizacionCodigo",
        },
        {
          type: "numeric",
          title: "Prefactura",
          field: "prefacturaCodigo",
        },
        {
          type: "numeric",
          title: "Proforma",
          field: "proformaCodigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "date",
          title: "Vencimiento",
          field: "fechaVence",
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaVence),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Destinatario",
          field: "clienteDestinatario",
        },
        {
          type: "string",
          title: "Dirección",
          field: "clienteDireccion",
        },
        {
          type: "string",
          title: "Tipo NCF",
          field: "tipoNcfNombre",
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
        },
        {
          type: "string",
          title: "Atención",
          field: "atencion",
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Vendedor",
          field: "vendedorNombre",
        },
        {
          type: "string",
          title: "Tipo Ingreso",
          field: "tipoIngresoNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.bruto),
          title: "Bruto",
          field: "bruto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.descuento),
          title: "Descuento",
          field: "descuento",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Neto",
          field: "neto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp.",
          field: "otrosImpuestos",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
        },
        {
          type: "boolean",
          title: "Crédito",
          field: "isCredito",
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
        },
        {
          type: "boolean",
          title: "Tiene Pago",
          field: "isPagado",
        },
        {
          type: "numeric",
          title: "Cuadre de Caja",
          field: "cuadreCajaCodigo",
        },
        {
          type: "string",
          title: "Estado en DGII",
          field: "encfStatus",
          hidden: true,
        },
        {
          type: "string",
          title: "Usuario",
          field: "usuarioNombre",
        },
      ];

    case "CxCFacturaProductos":
      return [
        {
          type: "string",
          title: "Referencia",
          field: "productoReferencia",
        },
        {
          type: "string",
          title: "Producto",
          field: "productoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Medida",
          field: "productoUnidadMedida",
          render: (rowData) => {
            return getAbbreviatura(rowData.productoUnidadMedida);
          },
        },
        {
          type: "numeric",
          title: "Cantidad",
          field: "cantidad",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.precio),
          title: "Precio",
          field: "precio",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.bruto),
          title: "Bruto",
          field: "bruto",
        },
        {
          type: "numeric",
          title: "Descuento %",
          field: "descuentoPor",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.descuento),
          title: "Descuento",
          field: "descuento",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Neto",
          field: "neto",
        },
        {
          type: "numeric",
          title: "ITBIS %",
          field: "itbisPor",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp.",
          field: "otrosImpuestos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
      ];

    case "CxCNotaCreditos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "NCF Afectado",
          field: "ncfAfectado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto",
          field: "monto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoItbis),
          title: "ITBIS",
          field: "montoItbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuadre de Caja",
          field: "cuadreCajaCodigo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
      ];

    case "CxCNotaDebitos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "clienteNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Dirección",
          field: "clienteDireccion",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "NCF Afectado",
          field: "ncfAfectado",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Ajuste",
          field: "isAjuste",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Tiene Pago",
          field: "isPagado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Cuadre de Caja",
          field: "cuadreCajaCodigo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
      ];

    case "CxCPrefacturas":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Destinatario",
          field: "clienteDestinatario",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Dirección",
          field: "clienteDireccion",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Vendedor",
          field: "vendedorNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.bruto),
          title: "Bruto",
          field: "bruto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.descuento),
          title: "Descuento",
          field: "descuento",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Neto",
          field: "neto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp.",
          field: "otrosImpuestos",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
        },
        {
          type: "boolean",
          title: "Factura",
          field: "facturaCodigo",
        },
        {
          type: "boolean",
          title: "Crédito",
          field: "isCredito",
        },
        {
          type: "string",
          title: "Creado Por",
          field: "usuarioNombre",
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
        },
      ];

    case "CxCProformas":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "date",
          title: "Vencimiento",
          field: "fechaVence",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaVence),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Destinatario",
          field: "clienteDestinatario",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Dirección",
          field: "clienteDireccion",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Vendedor",
          field: "vendedorNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.bruto),
          title: "Bruto",
          field: "bruto",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.descuento),
          title: "Descuento",
          field: "descuento",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Neto",
          field: "neto",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp.",
          field: "otrosImpuestos",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Factura",
          field: "facturaCodigo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Crédito",
          field: "isCredito",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "CxCRecibos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Cuenta CxC",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Destinatario",
          field: "clienteDestinatario",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Moneda Aplicada",
          field: "monedaAplicarNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Medio de Pago",
          field: "medioPagoNombre",
        },
        {
          type: "string",
          title: "Banco",
          field: "banco",
          hidden: true,
        },
        {
          type: "string",
          title: "Documento Bancario",
          field: "documento",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Depósito",
          field: "depositoCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuadre de Caja",
          field: "cuadreCajaCodigo",
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRecibido),
          title: "Recibido",
          field: "montoRecibido",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetItbis),
          title: "Ret. ITBIS",
          field: "montoRetItbis",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoRetIsr),
          title: "Ret. ISR",
          field: "montoRetIsr",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "CxCRecibosManual":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Destinatario",
          field: "clienteDestinatario",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "string",
          title: "Medio de Pago",
          field: "medioPagoNombre",
        },
        {
          type: "string",
          title: "Banco",
          field: "banco",
          hidden: true,
        },
        {
          type: "string",
          title: "Documento Bancario",
          field: "documento",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Depósito",
          field: "depositoCodigo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cuadre de Caja",
          field: "cuadreCajaCodigo",
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "CxCRestaurantMesas":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Ubicación",
          field: "ubicacion",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
        },
      ];

    case "CxCRestaurantMeseros":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
        },
      ];

    case "CxCResumenFacturasConsumo":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
        },
      ];

    case "SPCxCComisionVendedor":
      return [
        {
          type: "numeric",
          title: "Factura",
          field: "facturaCodigo",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "date",
          title: "Fecha FT",
          field: "facturaFecha",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return FormatearFecha(
              convertirTextoAFecha(rowData.facturaFecha),
              "DD-MM-YYYY"
            );
          },
        },
        {
          type: "date",
          title: "Fecha Pago",
          field: "reciboFecha",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return FormatearFecha(
              convertirTextoAFecha(rowData.reciboFecha),
              "DD-MM-YYYY"
            );
          },
        },
        {
          type: "numeric",
          title: "Días",
          field: "diasPago",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Monto FT sin ITBIS",
          field: "neto",
        },
        {
          type: "numeric",
          title: "Utilidad",
          field: "utilidadPor",
        },
        {
          type: "numeric",
          title: "%Comisión",
          field: "porcentajeComision",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoComision),
          title: "Comisión",
          field: "montoComision",
        },
      ];

    case "SPCxCComisionVendedorAll":
      return [
        {
          type: "numeric",
          title: "Factura",
          field: "facturaCodigo",
        },
        {
          type: "string",
          title: "Vendedor",
          field: "vendedorNombre",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "date",
          title: "Fecha FT",
          field: "facturaFecha",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return FormatearFecha(
              convertirTextoAFecha(rowData.facturaFecha),
              "DD-MM-YYYY"
            );
          },
        },
        {
          type: "date",
          title: "Fecha Pago",
          field: "reciboFecha",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return FormatearFecha(
              convertirTextoAFecha(rowData.reciboFecha),
              "DD-MM-YYYY"
            );
          },
        },
        {
          type: "numeric",
          title: "Días",
          field: "diasPago",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Monto FT sin ITBIS",
          field: "neto",
        },
        {
          type: "numeric",
          title: "Utilidad",
          field: "utilidadPor",
        },
        {
          type: "numeric",
          title: "%Comisión",
          field: "porcentajeComision",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoComision),
          title: "Comisión",
          field: "montoComision",
        },
      ];

    case "CxCSalidasCaja":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
          cellStyle: { maxWidth: 100 },
          headerStyle: { maxWidth: 100 },
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
        },
        {
          type: "numeric",
          title: "Cuadre de Caja",
          field: "cuadreCajaCodigo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
      ];

    case "CxPBuscadores":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Teléfono 1",
          field: "telefono1",
        },
        {
          type: "string",
          title: "Teléfono 2",
          field: "telefono2",
        },
        {
          type: "string",
          title: "Teléfono 3",
          field: "telefono3",
        },
        {
          type: "string",
          title: "Teléfono 4",
          field: "telefono4",
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
        },
        {
          type: "numeric",
          title: "Comisión",
          field: "comisionPorcentaje",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
        },
      ];

    case "CxPFacturas":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "numeric",
          title: "No. Orden",
          field: "ordenId",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "date",
          title: "Vencimiento",
          field: "fechaVence",
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaVence),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidorNombre",
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.netoServicio + rowData.netoBien),
          title: "Neto",
          field: "neto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp.",
          field: "otrosImpuestos",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
        },
        {
          type: "boolean",
          title: "Tiene Pago",
          field: "isPagado",
        },
        {
          type: "numeric",
          title: "Solicitud No.",
          field: "solicitudCodigo",
        },
        {
          type: "numeric",
          title: "Pesada No.",
          field: "pesadaCodigo",
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
        },
      ];

    case "CxPFacturaProductos":
      return [
        {
          type: "string",
          title: "Referencia",
          field: "productoReferencia",
        },
        {
          type: "string",
          title: "Producto",
          field: "productoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Medida",
          field: "productoUnidadMedida",
          render: (rowData) => {
            return getAbbreviatura(rowData.productoUnidadMedida);
          },
        },
        {
          type: "numeric",
          title: "Cantidad",
          field: "cantidad",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.costo),
          title: "Precio",
          field: "costo",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.bruto),
          title: "Bruto",
          field: "bruto",
        },
        {
          type: "numeric",
          title: "Descuento %",
          field: "descuentoPor",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.descuento),
          title: "Descuento",
          field: "descuento",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Neto",
          field: "neto",
        },
        {
          type: "numeric",
          title: "ITBIS %",
          field: "itbisPor",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
      ];

    case "CxPNotaCreditos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "suplidorRncCedula",
        },
        {
          type: "string",
          title: "Sulidor",
          field: "suplidorNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "NCF Afectado",
          field: "ncfAfectado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto",
          field: "monto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoItbis),
          title: "ITBIS",
          field: "montoItbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "CxPOrdenCompras":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidorNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.bruto),
          title: "Bruto",
          field: "bruto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.descuento),
          title: "Descuento",
          field: "descuento",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.neto),
          title: "Neto",
          field: "neto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp.",
          field: "otrosImpuestos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Cerrado",
          field: "isCerrado",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "CxPPesadas":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "numeric",
          title: "Código Pesador",
          field: "codigoPesador",
        },
        {
          type: "numeric",
          title: "Código Externo",
          field: "codigoExterno",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidorNombre",
        },
        {
          type: "string",
          title: "Producto",
          field: "productoNombre",
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
        },
        {
          type: "string",
          title: "Cuenta",
          field: "cuentaNombre",
        },
        {
          type: "string",
          title: "Pesador",
          field: "pesadorNombre",
        },
        {
          type: "string",
          title: "Buscador",
          field: "buscadorNombre",
        },
        {
          type: "string",
          title: "Factura",
          field: "facturaCodigo",
        },
        {
          type: "string",
          title: "NCF",
          field: "facturaNcf",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.sacosLlenos),
          title: "Sacos Llenos",
          field: "sacosLlenos",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.sacosVacios),
          title: "Sacos Vacios",
          field: "sacosVacios",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.sacosEntregados),
          title: "Sacos Entregados",
          field: "sacosEntregados",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.sacosDevueltos),
          title: "Sacos Devueltos",
          field: "sacosDevueltos",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pesoBruto),
          title: "Peso Bruto",
          field: "pesoBruto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pesoNeto),
          title: "Peso Neto",
          field: "pesoNeto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.humedad),
          title: "Humedad",
          field: "humedad",
        },
        {
          type: "numeric",
          render: (rowData) => formatNumberDecimals(rowData.fanegaCantidad, 9),
          title: "Fanega Cantidad",
          field: "fanegaCantidad",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.fanegaPrecio),
          title: "Fanega Precio",
          field: "pesoPrecio",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
        },
      ];

    case "CxPPesadores":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Teléfono 1",
          field: "telefono1",
        },
        {
          type: "string",
          title: "Teléfono 2",
          field: "telefono2",
        },
        {
          type: "string",
          title: "Teléfono 3",
          field: "telefono3",
        },
        {
          type: "string",
          title: "Teléfono 4",
          field: "telefono4",
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
        },
        {
          type: "numeric",
          title: "Comisión",
          field: "comisionPorcentaje",
        },
        {
          type: "boolean",
          title: "Es Empleado",
          field: "isEmpleado",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
        },
      ];

    case "CxPReembolsos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidorNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.netoServicio + rowData.netoBien),
          title: "Neto",
          field: "neto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.itbis),
          title: "ITBIS",
          field: "itbis",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.selectivo),
          title: "Selectivo",
          field: "selectivo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosImpuestos),
          title: "Otros Imp.",
          field: "otrosImpuestos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.propina),
          title: "Propina",
          field: "propina",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Solicitud No.",
          field: "solicitudCodigo",
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Tiene Pago",
          field: "isPagado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "CxPSuplidors":
      return [
        {
          type: "string",
          title: "RNC/Cédula",
          field: "rncCedula",
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "numeric",
          title: "Crédito Días",
          field: "condicionPago",
        },
        {
          type: "string",
          title: "Teléfono 1",
          field: "telefono1",
        },
        {
          type: "string",
          title: "Teléfono 2",
          field: "telefono2",
        },
        {
          type: "string",
          title: "Teléfono 3",
          field: "telefono3",
        },
        {
          type: "string",
          title: "Teléfono 4",
          field: "telefono4",
        },
        {
          type: "string",
          title: "E-Mail",
          field: "eMail",
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
        },
        {
          type: "string",
          title: "Contacto",
          field: "contacto",
        },
        {
          type: "string",
          title: "Grupo",
          field: "grupoNombre",
        },
        {
          type: "boolean",
          title: "Es Compras",
          field: "isInformal",
        },
        {
          type: "boolean",
          title: "Es Gasto Menor",
          field: "isGastoMenor",
        },
        {
          type: "boolean",
          title: "Es Pago Exterior",
          field: "isPagoExterior",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
        },
      ];

    case "CxPSuplidorGrupos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Cuenta CxP",
          field: "cuentaCxPNombre",
        },
      ];

    case "CxPNotaDebitos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Cuenta",
          field: "suplidorNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "suplidorRncCedula",
        },
        {
          type: "string",
          title: "Dirección",
          field: "suplidorDireccion",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "NCF",
          field: "ncf",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "NCF Afectado",
          field: "ncfAfectado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.totalTasa),
          title: "Mon. Base",
          field: "totalTasa",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.pendiente),
          title: "Pendiente",
          field: "pendiente",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Ajuste",
          field: "isAjuste",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "numeric",
          title: "Solicitud No.",
          field: "solicitudCodigo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Tiene Pago",
          field: "isPagado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "FinanciamientoPrestamos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "date",
          title: "Fecha Inicio",
          field: "fechaInicio",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return FormatearFecha(
              convertirTextoAFecha(rowData.fechaInicio),
              "DD-MM-YYYY"
            );
          },
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Frecuencia Pago",
          field: "frecuenciaNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoPrestamo),
          title: "Monto Prestamo",
          field: "montoPrestamo",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.montoGastosCierre),
          title: "Monto Gastos Cierre",
          field: "montoGastosCierre",
          cellStyle: { minWidth: 200 },
          headerStyle: { minWidth: 200 },
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.montoGastosLegales),
          title: "Monto Gastos Legales",
          field: "montoGastosLegales",
          cellStyle: { minWidth: 200 },
          headerStyle: { minWidth: 200 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoDesembolsar),
          title: "Monto a Desembolsar",
          field: "montoDesembolsar",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoInteres),
          title: "Monto Interes",
          field: "montoInteres",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total Prestamo",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoPago),
          title: "Monto Cuota",
          field: "montoPago",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasaAnual),
          title: "Tasa Anual",
          field: "tasaAnual",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.cantidadCuotas),
          title: "Cantidad Cuotas",
          field: "cantidadCuotas",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Autorizado por",
          field: "autorizadoPor",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Rechazado por",
          field: "rechazadoPor",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Cheque",
          field: "chequeCodigo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Transferencia",
          field: "transferenciaCodigo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "FinanciamientoReporteCarteraPrestamo":
      return [
        {
          type: "numeric",
          title: "Prestamo",
          field: "prestamoCodigo",
        },
        {
          type: "numeric",
          title: "Cuota",
          field: "cuotaNo",
        },
        {
          type: "date",
          title: "Fecha Pago",
          field: "fechaFormateada",
        },
        {
          type: "string",
          title: "Rnc/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.montoCapitalPendiente),
          title: "Capital Pendiente",
          field: "montoCapitalPendiente",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.montoInteresPendiente),
          title: "Interes Pendiente",
          field: "montoInteresPendiente",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.montoMoraPendiente),
          title: "Mora Pendiente",
          field: "montoMoraPendiente",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(
              +rowData.montoCapitalPendiente +
                +rowData.montoInteresPendiente +
                +rowData.montoMoraPendiente
            ),
          title: "Monto a Pagar",
          field: "montoPago",
        },
      ];

    case "FinanciamientoReporteCarteraPrestamoResumen":
      return [
        {
          type: "string",
          title: "Rnc/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.montoCapitalPendiente),
          title: "Capital Pendiente",
          field: "montoCapitalPendiente",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.montoInteresPendiente),
          title: "Interes Pendiente",
          field: "montoInteresPendiente",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(rowData.montoMoraPendiente),
          title: "Mora Pendiente",
          field: "montoMoraPendiente",
        },
        {
          type: "numeric",
          render: (rowData) =>
            FormatearNumero.format(
              +rowData.montoCapitalPendiente +
                +rowData.montoInteresPendiente +
                +rowData.montoMoraPendiente
            ),
          title: "Monto a Pagar",
          field: "montoPago",
        },
      ];

    case "FinanciamientoRecibos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "clienteRncCedula",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hiddenByColumnsButton: !utilizaCentroCosto,
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "InventarioAlmacens":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Grupo",
          field: "grupoNombre",
        },
        {
          type: "numeric",
          title: "Capacidad Máxima",
          field: "capacidad",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "InventarioAlmacenGrupos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "InventarioConduceAlmacens":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Almacén Grupo",
          field: "almacenGrupoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Proyecto",
          field: "proyectoNombre",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "InventarioConduceProyectoMaterialDetallado":
      return [
        {
          type: "numeric",
          title: "Conduce",
          field: "conduceCodigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Referencia",
          field: "productoReferencia",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Referencia",
          field: "productoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Medida",
          field: "productoUnidadMedida",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return getAbbreviatura(rowData.productoUnidadMedida);
          },
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
        },
        {
          type: "string",
          title: "Proyecto",
          field: "proyectoNombre",
        },
        {
          type: "numeric",
          title: "Cantidad",
          field: "cantidad",
        },
      ];

    case "InventarioConduceProyectoMaterialResumen":
      return [
        {
          type: "string",
          title: "Referencia",
          field: "productoReferencia",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Referencia",
          field: "productoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Medida",
          field: "productoUnidadMedida",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return getAbbreviatura(rowData.productoUnidadMedida);
          },
        },
        {
          type: "string",
          title: "Proyecto",
          field: "proyectoNombre",
        },
        {
          type: "numeric",
          title: "Cantidad",
          field: "cantidad",
        },
      ];

    case "InventarioCortesInventario":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Ejecutado",
          field: "isEjecutado",
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "InventarioCortesInventarioConteo":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
      ];

    case "InventarioEntradaAlmacens":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "InventarioLiquidacionAduanalTipoGastos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "InventarioLiquidacionAduanal":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "date",
          title: "Fecha de Llegada",
          field: "fechaLlegada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaLlegada),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Suplidor",
          field: "suplidorNombre",
        },
        {
          type: "string",
          title: "Moneda",
          field: "monedaNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Centro Costo",
          field: "centroCostoNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Proyecto",
          field: "centroCostoProyectoNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Sub-Proyecto",
          field: "centroCostoSubProyectoNombre",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.tasa),
          title: "Tasa",
          field: "tasa",
          hidden: true,
        },
        {
          type: "string",
          title: "Colecturia",
          field: "colecturia",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Acto",
          field: "acto",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Planilla",
          field: "planilla",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Declaración",
          field: "declaracion",
          hidden: true,
        },
        {
          type: "string",
          title: "Liquidación",
          field: "liquidacion",
          hidden: true,
        },
        {
          type: "string",
          title: "Conocimiento Embarque",
          field: "conocimientoEmbarque",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Detalle",
          field: "detalle",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Consolidado",
          field: "consolidado",
          hidden: true,
        },
        {
          type: "string",
          title: "Número BL",
          field: "numeroBL",
          hidden: true,
        },
        {
          type: "string",
          title: "Manifiesto",
          field: "manifiesto",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Deposito",
          field: "deposito",
          hidden: true,
        },
        {
          type: "string",
          title: "Documento",
          field: "documento",
          hidden: true,
        },
        {
          type: "string",
          title: "Regimen",
          field: "regimen",
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          title: "FOB",
          field: "montoFOB",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Seguro",
          field: "montoSeguro",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Flete",
          field: "montoFlete",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Otros",
          field: "montoOtros",
          hidden: true,
        },
        {
          type: "numeric",
          title: "CIF",
          field: "montoCIF",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Gravamen",
          field: "montoGravamen",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Selectivo",
          field: "montoSelectivo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "ITBIS",
          field: "montoITBIS",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Gastos",
          field: "montoGastos",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Art52",
          field: "montoArt52",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Multas",
          field: "montoMultas",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Monto Liquidación",
          field: "montoLiquidacion",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Cerrado",
          field: "isCerrado",
        },
      ];

    case "InventarioProducciones":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "numeric",
          title: "% Finalizado",
          field: "porFinalizado",
        },
        {
          type: "boolean",
          title: "Esta Finalizado",
          field: "isFinalizado",
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
        },
      ];

    case "InventarioProduccionEmpacadores":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Teléfono 1",
          field: "telefono1",
        },
        {
          type: "string",
          title: "Teléfono 2",
          field: "telefono2",
        },
        {
          type: "string",
          title: "Teléfono 3",
          field: "telefono3",
        },
        {
          type: "string",
          title: "Teléfono 4",
          field: "telefono4",
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
        },
      ];

    case "InventarioProduccionFormulas":
      return [
        {
          type: "string",
          title: "imagen",
          field: "imagen",
          render: (rowData) => {
            return (
              <img
                src={rowData.imagen ?? ""}
                alt="Imagen de Producto"
                width="100px"
                id="visorImagen"
              />
            );
          },
        },
        {
          type: "string",
          title: "Referencia",
          field: "productoReferencia",
        },
        {
          type: "string",
          title: "Producto",
          field: "productoNombre",
        },
        {
          type: "string",
          title: "Medida",
          field: "productoUnidadMedida",
          render: (rowData) => {
            return getAbbreviatura(rowData.productoUnidadMedida);
          },
        },
        {
          type: "string",
          title: "Procedimiento",
          field: "procedimiento",
        },
      ];

    case "InventarioProduccionFormulaProductos":
      return [
        {
          type: "string",
          title: "Referencia",
          field: "productoReferencia",
        },
        {
          type: "string",
          title: "Producto",
          field: "productoNombre",
        },
        {
          type: "string",
          title: "Medida",
          field: "productoUnidadMedida",
          render: (rowData) => {
            return getAbbreviatura(rowData.productoUnidadMedida);
          },
        },
      ];

    case "InventarioProductos":
      return [
        {
          type: "string",
          title: "imagen",
          field: "imagen",
          hidden: true,
          render: (rowData) => {
            return (
              <img
                src={rowData.imagen ?? ""}
                alt="Imagen de Producto"
                width="100px"
                id="visorImagen"
              />
            );
          },
        },
        {
          type: "string",
          title: "Referencia",
          field: "referencia",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Medida",
          field: "unidadMedida",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) => {
            return getAbbreviatura(rowData.unidadMedida);
          },
        },
        {
          type: "numeric",
          title: "Existencia",
          field: "existencia",
          render: (rowData) => {
            return rowData.existencias
              .reduce((acc: number, val: any) => acc + val.existencia, 0)
              .toLocaleString(undefined, {
                maximumFractionDigits: 9,
                minimumFractionDigits: 9,
              });
          },
        },
        {
          type: "string",
          title: "Pasillo",
          field: "pasillo",
          hidden: true,
        },
        {
          type: "string",
          title: "Gondola",
          field: "gondola",
          hidden: true,
        },
        {
          type: "string",
          title: "Tramo",
          field: "tramo",
          hidden: true,
        },
        {
          type: "string",
          title: "Grupo",
          field: "grupoNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Sub-Grupo",
          field: "subGrupoNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "ITBIS",
          field: "tipoITBISNombre",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.costo),
          title: "Costo",
          field: "costo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cant. Ideal",
          field: "cantIdeal",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Cant. Minima",
          field: "cantMinima",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Cta. Ingreso",
          field: "cuentaIngresoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Cta. Inventario",
          field: "cuentaInventarioNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Cta. Costo",
          field: "cuentaCostoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Es Servicio",
          field: "isServicio",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "InventarioProductoGrupos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Cta. Ingreso",
          field: "cuentaIngresoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Cta. Inventario",
          field: "cuentaInventarioNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Cta. Costo",
          field: "cuentaCostoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Tipo Inventario",
          field: "tipoInventarioNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "boolean",
          title: "No usar en FT.",
          field: "noFacturar",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "InventarioProductoTipoInventarios":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "InventarioProductoSubGrupos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Tipo Inventario",
          field: "tipoInventarioNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Grupo",
          field: "grupoNombre",
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "InventarioProyecto":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Ubicación",
          field: "ubicacion",
          hidden: true,
        },
        {
          type: "string",
          title: "Cuenta Contable",
          field: "cuentaCostoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "InventarioRepExistencia":
      return [
        {
          type: "string",
          title: "Tipo Inven.",
          field: "tipoInventarioNombre",
        },
        {
          type: "string",
          title: "Grupo",
          field: "grupoNombre",
        },
        {
          type: "string",
          title: "SubGrupo",
          field: "subGrupoNombre",
        },
        {
          type: "string",
          title: "Referencia",
          field: "productoReferencia",
        },
        {
          type: "string",
          title: "Producto",
          field: "productoNombre",
        },
        {
          type: "string",
          title: "Medida",
          field: "productoUnidadMedida",
          render: (rowData) => {
            return getAbbreviatura(rowData.productoUnidadMedida);
          },
        },
        {
          type: "numeric",
          render: (rowData) => formatNumberDecimals(rowData.existencia, 9),
          title: "Existencia",
          field: "existencia",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.costo),
          title: "Costo",
          field: "costo",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Costo Total",
          field: "total",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.precio),
          title: "Precio",
          field: "precio",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.precioTotal),
          title: "Precio Total",
          field: "precioTotal",
        },
      ];

    case "InventarioRepProductoMovimiento":
      return [
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
        },
        {
          type: "string",
          title: "Inventario",
          field: "tipoInventarioNombre",
        },
        {
          type: "string",
          title: "Grupo",
          field: "grupoNombre",
        },
        {
          type: "string",
          title: "Sub-Grupo",
          field: "subGrupoNombre",
        },
        {
          type: "string",
          title: "Referencia",
          field: "productoReferencia",
        },
        {
          type: "string",
          title: "Producto",
          field: "productoNombre",
        },
        {
          type: "string",
          title: "Medida",
          field: "productoUnidadMedida",
          render: (rowData) => {
            return getAbbreviatura(rowData.productoUnidadMedida);
          },
        },
        {
          type: "numeric",
          title: "Código",
          field: "documentoCodigo",
        },
        {
          type: "string",
          title: "Doc.",
          field: "tipo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
        },
        {
          type: "numeric",
          title: "Costo",
          field: "costo",
        },
        {
          type: "numeric",
          title: "Existencia Ant.",
          field: "cantidadAnterior",
        },
        {
          type: "numeric",
          title: "Entrada",
          field: "cantidadEntrada",
        },
        {
          type: "numeric",
          title: "Salida",
          field: "cantidadSalida",
        },
        {
          type: "numeric",
          title: "Existencia Act.",
          field: "cantidadActual",
        },
      ];

    case "InventarioRequisicions":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "string",
          title: "Solicitado Por",
          field: "creadoPor",
        },
        {
          type: "string",
          title: "Autorizado Por",
          field: "autorizadoPor",
        },
        {
          type: "string",
          title: "Rechazada Por",
          field: "rechazadoPor",
        },
        {
          type: "numeric",
          title: "Orden Compra",
          field: "ordenCodigo",
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "InventarioSalidaAlmacens":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Almacén Grupo",
          field: "almacenGrupoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "InventarioTransferenciaAlmacens":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fechaFormateada",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
        },
        {
          type: "string",
          title: "Almacén Origen",
          field: "almacenOrigenNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Almacén Destino",
          field: "almacenDestinoNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
          hidden: true,
        },
      ];

    case "NominaDepartamentos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "Nominas":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Tipo de Nómina",
          field: "tipoNomina",
          render: (rowData) =>
            //@ts-ignore - ignora index signature
            TIPO_NOMINA_LABEL[rowData.tipoNomina as number].toUpperCase(),
        },
        {
          type: "date",
          title: "Fecha Desde",
          field: "fechaDesde",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaDesde),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "date",
          title: "Fecha Hasta",
          field: "fechaHasta",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaHasta),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Está Cerrado",
          field: "isCerrado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
      ];

    case "NominaDetalle":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "empleadoNombre",
        },
        {
          type: "string",
          title: "Cédula/Pasaporte",
          field: "empleadoCedula",
          render: (rowData) => rowData.empleadoCedula || rowData.empleadoCedula,
        },
        {
          type: "string",
          title: "Tipo de Pago",
          field: "tipoPago",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          lookup: { 0: "Nómina Electrónica", 1: "Cheque", 2: "Otro Medio" },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoBruto),
          title: "Mónto Bruto",
          field: "montoBruto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosPagos),
          title: "Otros Pagos",
          field: "otrosPagos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "blue" }}>
              {FormatearNumero.format(rowData.totalPagos)}
            </span>
          ),
          title: "Total de Pagos",
          field: "totalPagos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosDescuentos),
          title: "Otros Déscuentos",
          field: "otrosDescuentos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.AFPEmpleado),
          title: "AFP Empleado",
          field: "AFPEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ARSEmpleado),
          title: "ARS Empleado",
          field: "ARSEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ISREmpleado),
          title: "ISR Empleado",
          field: "ISREmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "red" }}>
              {FormatearNumero.format(rowData.totalDescuentos)}
            </span>
          ),
          title: "Total Déscuentos",
          field: "totalDescuentos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "green" }}>
              {FormatearNumero.format(rowData.totalEmpleado)}
            </span>
          ),
          title: "Total Empleado",
          field: "totalEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.infotep),
          title: "Infotep",
          field: "infotep",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.riesgoLaboral),
          title: "Riesgo Laboral",
          field: "riesgoLaboral",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.AFPEmpresa),
          title: "AFP Empresa",
          field: "AFPEmpresa",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ARSEmpresa),
          title: "ARS Empresa",
          field: "ARSEmpresa",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <strong>{FormatearNumero.format(rowData.totalPatrono)}</strong>
          ),
          title: "Total Empresa",
          field: "totalPatrono",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "NominaDetalleReporteDetallado":
      return [
        {
          type: "numeric",
          title: "Nomina No.",
          field: "codigo",
        },
        {
          type: "string",
          title: "Nombre",
          field: "empleadoNombre",
        },
        {
          type: "string",
          title: "Cédula/Pasaporte",
          field: "empleadoCedula",
          render: (rowData) => rowData.empleadoCedula || rowData.empleadoCedula,
        },
        {
          type: "date",
          title: "Fecha Entrada",
          field: "empleadoFechaEntrada",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.empleadoFechaEntrada),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "EMail",
          field: "empleadoEmail",
        },
        {
          type: "string",
          title: "Posicion",
          field: "empleadoPosicion",
        },
        {
          type: "string",
          title: "Tipo de Pago",
          field: "empleadoTipoPago",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          lookup: { 0: "Nómina Electrónica", 1: "Cheque", 2: "Otro Medio" },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoBruto),
          title: "Mónto Bruto",
          field: "montoBruto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosPagos),
          title: "Otros Pagos",
          field: "otrosPagos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "blue" }}>
              {FormatearNumero.format(rowData.totalPagos)}
            </span>
          ),
          title: "Total de Pagos",
          field: "totalPagos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosDescuentos),
          title: "Otros Déscuentos",
          field: "otrosDescuentos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.AFPEmpleado),
          title: "AFP Empleado",
          field: "AFPEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ARSEmpleado),
          title: "ARS Empleado",
          field: "ARSEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ISREmpleado),
          title: "ISR Empleado",
          field: "ISREmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "red" }}>
              {FormatearNumero.format(rowData.totalDescuentos)}
            </span>
          ),
          title: "Total Déscuentos",
          field: "totalDescuentos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "green" }}>
              {FormatearNumero.format(rowData.totalEmpleado)}
            </span>
          ),
          title: "Total Empleado",
          field: "totalEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.infotep),
          title: "Infotep",
          field: "infotep",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.riesgoLaboral),
          title: "Riesgo Laboral",
          field: "riesgoLaboral",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.AFPEmpresa),
          title: "AFP Empresa",
          field: "AFPEmpresa",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ARSEmpresa),
          title: "ARS Empresa",
          field: "ARSEmpresa",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <strong>{FormatearNumero.format(rowData.totalPatrono)}</strong>
          ),
          title: "Total Empresa",
          field: "totalPatrono",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "NominaDetalleReporteResumido":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "empleadoNombre",
        },
        {
          type: "string",
          title: "Cédula/Pasaporte",
          field: "empleadoCedula",
          render: (rowData) => rowData.empleadoCedula || rowData.empleadoCedula,
        },
        {
          type: "date",
          title: "Fecha Entrada",
          field: "empleadoFechaEntrada",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.empleadoFechaEntrada),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "EMail",
          field: "empleadoEmail",
        },
        {
          type: "string",
          title: "Posicion",
          field: "empleadoPosicion",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoBruto),
          title: "Mónto Bruto",
          field: "montoBruto",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosPagos),
          title: "Otros Pagos",
          field: "otrosPagos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "blue" }}>
              {FormatearNumero.format(rowData.totalPagos)}
            </span>
          ),
          title: "Total de Pagos",
          field: "totalPagos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.otrosDescuentos),
          title: "Otros Déscuentos",
          field: "otrosDescuentos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.AFPEmpleado),
          title: "AFP Empleado",
          field: "AFPEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ARSEmpleado),
          title: "ARS Empleado",
          field: "ARSEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ISREmpleado),
          title: "ISR Empleado",
          field: "ISREmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "red" }}>
              {FormatearNumero.format(rowData.totalDescuentos)}
            </span>
          ),
          title: "Total Déscuentos",
          field: "totalDescuentos",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <span style={{ color: "green" }}>
              {FormatearNumero.format(rowData.totalEmpleado)}
            </span>
          ),
          title: "Total Empleado",
          field: "totalEmpleado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.infotep),
          title: "Infotep",
          field: "infotep",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.riesgoLaboral),
          title: "Riesgo Laboral",
          field: "riesgoLaboral",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.AFPEmpresa),
          title: "AFP Empresa",
          field: "AFPEmpresa",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ARSEmpresa),
          title: "ARS Empresa",
          field: "ARSEmpresa",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          render: (rowData) => (
            <strong>{FormatearNumero.format(rowData.totalPatrono)}</strong>
          ),
          title: "Total Empresa",
          field: "totalPatrono",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "NominaEmpleados":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
          render: (rowData) =>
            rowData.nombre +
            " " +
            (rowData.nombre2 || "") +
            " " +
            rowData.apellido1 +
            " " +
            (rowData.apellido2 || ""),
        },
        {
          type: "string",
          title: "Cédula",
          field: "cedula",
        },
        {
          type: "string",
          title: "Pasaporte",
          field: "pasaporte",
        },
        {
          type: "string",
          title: "Tipo de Pago",
          field: "tipoPago",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          lookup: { 0: "Nómina Electrónica", 1: "Cheque", 2: "Otro Medio" },
          hidden: true,
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 1",
          field: "telefono1",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Teléfono 2",
          field: "telefono2",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 3",
          field: "telefono3",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 4",
          field: "telefono4",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Estado Civil",
          field: "estadoCivil",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "date",
          title: "Fecha Nacimiento",
          field: "fechaNacimiento",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaNacimiento),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "date",
          title: "Fecha Graduado",
          field: "fechaGraduado",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaGraduado),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "date",
          title: "Fecha Entrada",
          field: "fechaEntrada",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaEntrada),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "date",
          title: "Fecha Salida",
          field: "fechaSalida",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaSalida),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "string",
          title: "Sexo",
          field: "sexo",
          hidden: true,
        },
        {
          type: "string",
          title: "E-Mail",
          field: "eMail",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.sueldoMensual),
          title: "Sueldo Mensual",
          field: "sueldoMensual",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Posición",
          field: "posicionNombre",
        },
        {
          type: "string",
          title: "Banco",
          field: "bancoNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Cuenta Bancaria",
          field: "bancoCuenta",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Departamento",
          field: "departamentoNombre",
        },
        {
          type: "string",
          title: "Suplidor AFP",
          field: "supAFPNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Suplidor ARS",
          field: "supARSNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Cuenta Contable",
          field: "cuentaCobrarNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Es Extranjero",
          field: "isExtranjero",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Está Cancelado",
          field: "isCancelado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "NominaEmpleadoNovedadFijos":
      return [
        {
          type: "string",
          title: "Empleado",
          field: "empleadoNombre",
        },
        {
          type: "string",
          title: "Renglón",
          field: "novedadNombre",
        },
        {
          type: "string",
          title: "Ejecuta en Quincena",
          field: "quincena",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          lookup: { 0: "Todas", 1: "Primera", 2: "Segunda" },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto",
          field: "monto",
        },
      ];

    case "NominaEmpleadoNovedadVariables":
      return [
        {
          type: "string",
          title: "Empleado",
          field: "empleadoNombre",
        },
        {
          type: "string",
          title: "Tipo de Nomina",
          field: "tipoNominaId",
          render: (rowData) =>
            //@ts-ignore - ignora index signature
            TIPO_NOMINA_LABEL[rowData.tipoNomina]?.toUpperCase(),
        },
        {
          type: "string",
          title: "Renglón",
          field: "novedadNombre",
        },
        {
          type: "date",
          title: "Fecha ejecutada",
          field: "fechaFormateada",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Monto",
          field: "monto",
        },
      ];

    case "NominaEmpleadoSueldosPorNomina":
      return [
        {
          type: "string",
          title: "Tipo de Nómina",
          field: "tipoNomina",
          render: (rowData) =>
            //@ts-ignore - ignora index signature
            TIPO_NOMINA_LABEL[rowData.tipoNomina as number].toUpperCase(),
        },
        {
          type: "string",
          title: "Fecha Desde",
          field: "fechaDesde",
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaDesde),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "Fecha Hasta",
          field: "fechaHasta",
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaHasta),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
      ];

    case "NominaPrestamos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Empleado",
          field: "empleadoNombre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoPrestamo),
          title: "Monto del Prestamo",
          field: "montoPrestamo",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoCuota),
          title: "Monto Cuota",
          field: "montoCuota",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoPagado),
          title: "Monto Pagado",
          field: "montoPagado",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoPendiente),
          title: "Monto Pendiente",
          field: "montoPendiente",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "date",
          title: "Fecha Inicio",
          field: "fechaInicio",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaInicio),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "date",
          title: "Fecha Fin Estimada",
          field: "fechaFin",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaFin),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "numeric",
          title: "Cuotas Totales",
          field: "cuotas",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Cuotas Pagadas",
          field: "cuotasPagadas",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Está Pagado",
          field: "isPagado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "NominaNovedades":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Tipo Cálculo",
          field: "calculo",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Cuenta Contable",
          field: "cuentaNombre",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
        },
        {
          type: "boolean",
          title: "Aplica AFP",
          field: "isAplicaAFP",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Aplica ARS",
          field: "isAplicaARS",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Aplica ISR",
          field: "isAplicaISR",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Aplica Riesgo Laboral",
          field: "isAplicaRiesgoLaboral",
          cellStyle: { minWidth: 200 },
          headerStyle: { minWidth: 200 },
        },
        {
          type: "boolean",
          title: "Aplica Infotep",
          field: "isAplicaInfotep",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Aplica Regalia",
          field: "isAplicaRegalia",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "RRHHAccions":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Empleado",
          field: "empleadoNombre",
          render: (rowData) =>
            rowData.empleadoIdentificacion + " - " + rowData.empleadoNombre,
        },
        {
          type: "string",
          title: "Acción",
          field: "accionNombre",
          render: (rowData) =>
            rowData.clasificacionNombre + " - " + rowData.accionNombre,
        },
        {
          type: "string",
          title: "Fecha Efectiva",
          field: "fechaEfectiva",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaEfectiva),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "numeric",
          title: "Sueldo Actual",
          field: "sueldoActual",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Sueldo Nuevo",
          field: "sueldoNuevo",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Posición Actual",
          field: "posicionActualNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Posición Nueva",
          field: "posicionNuevoNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Departamento Actual",
          field: "departamentoActualNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Departamento Nuevo",
          field: "departamentoNuevoNombre",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Licencia Días",
          field: "licenciaDias",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "numeric",
          title: "Licencia Fecha Inicio",
          field: "licenciaFechaInicio",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.licenciaFechaInicio),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "numeric",
          title: "Licencia Fecha Fin",
          field: "licenciaFechaFin",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.licenciaFechaFin),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "boolean",
          title: "Licencia con Disfrute de Sueldo",
          field: "licenciaIsDisfruteSueldo",
          hidden: true,
        },
        {
          type: "numeric",
          title: "Salida Fecha",
          field: "salidaFecha",
          cellStyle: { minWidth: 100 },
          headerStyle: { minWidth: 100 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Salida con Disfrute de Sueldo",
          field: "salidaIsDisfruteSueldo",
          hidden: true,
        },
      ];

    case "RRHHNominaRegalia":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "numeric",
          title: "Año",
          field: "ano",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
      ];

    case "RRHHNominaRegaliaDetalle":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "empleadoNombre",
        },
        {
          type: "string",
          title: "Cédula/Pasaporte",
          field: "empleadoCedula",
          render: (rowData) => rowData.empleadoCedula || rowData.empleadoCedula,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.enero),
          title: "Enero",
          field: "enero",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.febrero),
          title: "Febrero",
          field: "febrero",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.marzo),
          title: "Marzo",
          field: "marzo",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.abril),
          title: "Abril",
          field: "abril",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.mayo),
          title: "Mayo",
          field: "mayo",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.junio),
          title: "Junio",
          field: "junio",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.julio),
          title: "Julio",
          field: "julio",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.agosto),
          title: "Agosto",
          field: "agosto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.septiembre),
          title: "Septiembre",
          field: "septiembre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.octubre),
          title: "Octubre",
          field: "octubre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.noviembre),
          title: "Noviembre",
          field: "noviembre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.diciembre),
          title: "Diciembre",
          field: "diciembre",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.total),
          title: "Total",
          field: "total",
        },
      ];

    case "RRHHPosicion":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "RRHHSolicitudEmpleos":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fecha",
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
          render: (rowData) =>
            rowData.nombre +
            " " +
            (rowData.nombre2 || "") +
            " " +
            rowData.apellido1 +
            " " +
            (rowData.apellido2 || ""),
        },
        {
          type: "string",
          title: "Cédula",
          field: "cedula",
        },
        {
          type: "string",
          title: "Pasaporte",
          field: "pasaporte",
        },
        {
          type: "string",
          title: "Dirección",
          field: "direccion",
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 1",
          field: "telefono1",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "string",
          title: "Teléfono 2",
          field: "telefono2",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 3",
          field: "telefono3",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Teléfono 4",
          field: "telefono4",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Estado Civil",
          field: "estadoCivil",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "date",
          title: "Fecha Nacimiento",
          field: "fechaNacimiento",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaNacimiento),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "date",
          title: "Fecha Graduado",
          field: "fechaGraduado",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaGraduado),
              "DD-MM-YYYY"
            ),
          hidden: true,
        },
        {
          type: "string",
          title: "Sexo",
          field: "sexo",
          hidden: true,
        },
        {
          type: "string",
          title: "E-Mail",
          field: "eMail",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.sueldoMensual),
          title: "Sueldo Mensual",
          field: "sueldoMensual",
          cellStyle: { minWidth: 250 },
          headerStyle: { minWidth: 250 },
          hidden: true,
        },
        {
          type: "string",
          title: "Posición",
          field: "posicionNombre",
        },
        {
          type: "string",
          title: "Suplidor AFP",
          field: "supAFPNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "string",
          title: "Suplidor ARS",
          field: "supARSNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Es Extranjero",
          field: "isExtranjero",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          hidden: true,
        },
        {
          type: "boolean",
          title: "Está Anulado",
          field: "isAnulado",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "SPContabilidadPresupuestoCuentaEjecucion":
      return [
        {
          type: "numeric",
          title: "Presupuesto Código",
          field: "presupuestoCodigo",
        },
        {
          type: "string",
          title: "Mes",
          field: "mes",
          render: (rowData) => NombreMes(rowData.mes) + " " + rowData.ano,
        },
        {
          type: "string",
          title: "Cuenta",
          field: "cuenta",
          render: (rowData) => rowData.cuenta + " - " + rowData.cuentaNombre,
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.monto),
          title: "Presupuestado",
          field: "monto",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.ejecutado),
          title: "Ejecutado",
          field: "ejecutado",
        },
        {
          type: "numeric",
          render: (rowData) => FormatearNumero.format(rowData.montoPorEjecutar),
          title: "Por Ejecutar",
          field: "montoPorEjecutar",
        },
        {
          type: "numeric",
          title: "% Ejecutado",
          field: "porEjecutado",
          render: (rowData) =>
            (+rowData.porEjecutado).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
        },
      ];

    case "TallerMecanicaAreas":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "TallerMecanicaAreaEstadosServicios":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Area",
          field: "areaNombre",
        },
        {
          type: "numeric",
          title: "Orden",
          field: "orden",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "TallerMecanicaOrdens":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Fecha",
          field: "fecha",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) =>
            `${FormatearFecha(
              new Date(rowData.fecha),
              "DD-MM-YYYY"
            )}, ${new Date(rowData.fecha).toLocaleTimeString()}`,
        },
        {
          type: "string",
          title: "Fecha de Compromiso",
          field: "fechaCompromiso",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaCompromiso),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "Fecha de Entrega",
          field: "fechaEntrega",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
          render: (rowData) =>
            rowData.fechaEntrega
              ? FormatearFecha(
                  convertirTextoAFecha(rowData.fechaEntrega),
                  "DD-MM-YYYY"
                )
              : "",
        },
        {
          type: "string",
          title: "Tipo de Orden",
          field: "tipoOrdenNombre",
          cellStyle: { minWidth: 200 },
          headerStyle: { minWidth: 200 },
          hidden: true,
        },
        {
          type: "string",
          title: "Area",
          field: "areaNombre",
          hidden: true,
        },
        {
          type: "string",
          title: "Estado",
          field: "estadoNombre",
        },
        {
          type: "string",
          title: "Observación",
          field: "observacion",
          hidden: true,
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Placa",
          field: "vehiculoPlaca",
        },
        {
          type: "string",
          title: "Marca",
          field: "vehiculoMarca",
        },
        {
          type: "string",
          title: "Modelo",
          field: "vehiculoModelo",
          hidden: true,
        },
        {
          type: "string",
          title: "Color",
          field: "vehiculoColor",
          hidden: true,
        },
        {
          type: "string",
          title: "Año",
          field: "vehiculoAno",
          hidden: true,
        },
        {
          type: "string",
          title: "Kilometros",
          field: "kilometros",
          hidden: true,
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "TallerMecanicaOrdenEstadosLogs":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Estado",
          field: "estadoNombre",
        },
        {
          type: "string",
          title: "Fecha y Hora",
          field: "fechaHora",
          cellStyle: { minWidth: 200 },
          headerStyle: { minWidth: 200 },
          render: (rowData) => {
            const fecha = new Date(rowData.fechaHora);
            return fecha.toLocaleString();
          },
        },
        {
          type: "numeric",
          title: "Horas",
          field: "horas",
        },
        {
          type: "numeric",
          title: "Minutos",
          field: "minutos",
        },
        {
          type: "string",
          title: "Area",
          field: "areaNombre",
        },
        {
          type: "string",
          title: "Tipo",
          field: "tipoOrdenNombre",
        },
        {
          type: "string",
          title: "Vehículo",
          field: "vehiculoNombre",
          render: (rowData) =>
            rowData.vehiculoPlaca +
            " - " +
            rowData.vehiculoMarca +
            " - " +
            rowData.vehiculoModelo +
            " - " +
            rowData.vehiculoColor +
            " - " +
            rowData.vehiculoAno,
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Entrada",
          field: "fecha",
          render: (rowData) =>
            FormatearFecha(convertirTextoAFecha(rowData.fecha), "DD-MM-YYYY"),
        },
        {
          type: "string",
          title: "Compromiso",
          field: "fechaCompromiso",
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaCompromiso),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "Entrega",
          field: "fechaEntrega",
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaEntrega),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "TallerMecanicaMantenimientos":
      return [
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Teléfono",
          field: "clienteTelefono1",
        },
        {
          type: "string",
          title: "Prox. Mant.",
          field: "fechaMantenimiento",
          render: (rowData) =>
            FormatearFecha(
              convertirTextoAFecha(rowData.fechaMantenimiento),
              "DD-MM-YYYY"
            ),
        },
        {
          type: "string",
          title: "Placa",
          field: "vehiculoPlaca",
        },
        {
          type: "string",
          title: "Modelo",
          field: "vehiculoModelo",
        },
        {
          type: "string",
          title: "Marca",
          field: "vehiculoMarca",
        },
        {
          type: "string",
          title: "Año",
          field: "vehiculoAno",
        },
        {
          type: "string",
          title: "Color",
          field: "vehiculoColor",
        },
        {
          type: "string",
          title: "Asignado",
          field: "vehiculoAsignado",
        },
      ];

    case "TallerMecanicaMecanicos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "TallerMecanicaOrdenTipos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "TallerMecanicaVehiculos":
      return [
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
        },
        {
          type: "string",
          title: "Placa",
          field: "placa",
        },
        {
          type: "string",
          title: "Marca",
          field: "marca",
        },
        {
          type: "string",
          title: "Modelo",
          field: "modelo",
        },
        {
          type: "string",
          title: "Color",
          field: "color",
        },
        {
          type: "numeric",
          title: "Año",
          field: "ano",
        },
        {
          type: "string",
          title: "VIN",
          field: "vin",
        },
        {
          type: "string",
          title: "Asignado",
          field: "asignado",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "VisitaAreas":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "VisitaDestinos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Area",
          field: "areaNombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "VisitaVisitanteEmpresas":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "VisitaVisitanteTipos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Está Inactivo",
          field: "isInactivo",
          hidden: true,
        },
      ];

    case "VisitaVisita":
      return [
        {
          type: "string",
          title: "Entrada",
          field: "fechaEntrada",
          render: (rowData) => {
            const fecha = new Date(rowData.fechaEntrada);
            return fecha.toLocaleString();
          },
        },
        {
          type: "string",
          title: "Salida",
          field: "fechaSalida",
          render: (rowData) => {
            const fecha = new Date(rowData.fechaSalida);
            return fecha.toLocaleString();
          },
        },
        {
          type: "string",
          title: "Horas",
          field: "tiempoHoras",
        },
        {
          type: "string",
          title: "Minutos",
          field: "tiempoMinutos",
        },
        {
          type: "string",
          title: "RNC/Cédula",
          field: "visitanteRncCedula",
        },
        {
          type: "string",
          title: "Visitante",
          field: "visitanteNombre",
        },
        {
          type: "string",
          title: "Area",
          field: "areaNombre",
        },
        {
          type: "string",
          title: "Destino",
          field: "destinoNombre",
        },
        {
          type: "string",
          title: "Empresa",
          field: "empresaNombre",
        },
        {
          type: "string",
          title: "Tipo Visitante",
          field: "tipoNombre",
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
      ];

    case "VisitaVisitantes":
      return [
        {
          type: "string",
          title: "RNC/Cédula",
          field: "rncCedula",
        },
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Tipo",
          field: "tipoNombre",
        },
        {
          type: "string",
          title: "Empresa",
          field: "empresaNombre",
        },
        {
          type: "boolean",
          title: "Bloqueado",
          field: "isBloqueado",
        },
        {
          type: "boolean",
          title: "Dentro",
          field: "isDentro",
        },
      ];

    case "OrdenServicioTecnicos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Cédula",
          field: "rncCedula",
        },
        {
          type: "string",
          title: "E-mail",
          field: "email",
        },
        {
          type: "string",
          title: "Posición",
          field: "posicion",
        },
        {
          type: "string",
          title: "Almacén",
          field: "almacenNombre",
        },
        {
          type: "boolean",
          title: "Notificar Conduce",
          field: "isNotificarConduce",
        },
        {
          type: "boolean",
          title: "Inactivo",
          field: "isInactivo",
        },
      ];

    case "OrdenServicioEstados":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "string",
          title: "Color",
          field: "color",
          render: (rowData) => {
            return (
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  lineHeight: "60px",
                  borderRadius: "50%",
                  backgroundColor: `${
                    rowData.color?.includes("#")
                      ? rowData.color
                      : "#" + rowData.color
                  }`,
                  color: "white",
                  textAlign: "center",
                }}
              >
                {rowData.color}
              </div>
            );
          },
        },
        {
          type: "string",
          title: "Evento",
          field: "numEventoNombre",
        },
        {
          type: "boolean",
          title: "Inactivo",
          field: "isInactivo",
        },
      ];

    case "OrdenServicioTipos":
      return [
        {
          type: "string",
          title: "Nombre",
          field: "nombre",
        },
        {
          type: "boolean",
          title: "Inactivo",
          field: "isInactivo",
        },
        {
          type: "boolean",
          title: "Requiere Facturar",
          field: "isFacturar",
        },
        {
          type: "boolean",
          title: "Es de Garantia",
          field: "isGarantia",
        },
      ];

    case "OrdenServicioOrdenes":
      return [
        {
          type: "numeric",
          title: "Código",
          field: "codigo",
        },
        {
          type: "string",
          title: "Cliente",
          field: "clienteNombre",
          cellStyle: { paddingLeft: "0.5%" },
          headerStyle: { paddingLeft: "0.5%" },
        },
        {
          type: "string",
          title: "Rnc/Cedula",
          field: "clienteRncCedula",
        },
        {
          type: "date",
          title: "Fecha",
          field: "fecha",
        },
        {
          type: "date",
          title: "Fecha Compromiso",
          field: "fechaCompromiso",
        },
        {
          type: "string",
          title: "Tipo de Orden",
          field: "tipoOrdenNombre",
          cellStyle: { paddingLeft: "0.5%" },
          headerStyle: { paddingLeft: "0.5%" },
        },
        {
          type: "string",
          title: "Estado",
          field: "estadoNombre",
          render: (rowData) => {
            return (
              <Grid container item spacing={1}>
                <Grid
                  item
                  xs={3}
                  sm={7}
                  id="estadoNombre"
                  style={{ margin: "auto" }}
                >
                  {rowData.estadoNombre}
                </Grid>
                <Grid item xs={3} sm={5} id="estadoColor">
                  <div>
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        lineHeight: "40px",
                        borderRadius: "50%",
                        backgroundColor: `${
                          rowData.estadoColor?.includes("#")
                            ? rowData.estadoColor
                            : "#" + rowData.estadoColor
                        }`,
                        color: "white",
                      }}
                    ></div>
                  </div>
                </Grid>
              </Grid>
            );
          },
          cellStyle: { minWidth: 150 },
          headerStyle: { minWidth: 150 },
        },
        {
          type: "numeric",
          title: "Factura",
          field: "facturaCodigo",
        },
        {
          type: "numeric",
          title: "Proforma",
          field: "proformaCodigo",
        },
        {
          type: "numeric",
          title: "Conduce",
          field: "conduceCodigo",
        },
        {
          type: "numeric",
          title: "Cotización",
          field: "proformaCodigo",
        },
        {
          type: "string",
          title: "Localidad",
          field: "localidadNombre",
          cellStyle: { paddingLeft: "0.5%" },
          headerStyle: { paddingLeft: "0.5%" },
        },
        {
          type: "string",
          title: "Descripción Orden",
          field: "observacion",
        },
        {
          type: "string",
          title: "Trabajo Realizado",
          field: "observacionCierre",
          cellStyle: { maxWidth: "50%" },
        },
        {
          type: "string",
          title: "Creado Por",
          field: "creadoPorNombre",
        },
        {
          type: "boolean",
          title: "Esta Cerrado",
          field: "isCerrado",
        },
        {
          type: "boolean",
          title: "Esta Anulado",
          field: "isAnulado",
        },
      ];

    case "Usuarios":
      return [
        {
          type: "string",
          title: "Usuario",
          field: "username",
        },
        {
          type: "string",
          title: "Email",
          field: "email",
        },
        {
          type: "string",
          title: "Role",
          field: "roleNombre",
        },
        {
          type: "string",
          title: "Almacén asignado",
          field: "almacenGrupoNombre",
        },
        {
          type: "boolean",
          title: "Usa Cuadre de Caja",
          field: "utilizaCuadreCaja",
        },
      ];

    default:
      return [{}];
  }
};
