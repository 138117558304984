const drawerWidth = 280;

export const Styles = (theme: any) => ({
  root: {
    display: "flex",
    backgroundColor: "#f8f9fc",
    flex: "1 0 auto",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  appBar: {
    background: "white",
    height: "5rem",
    lineHeight: "5rem",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: 0,
    background: "white",
    height: "5rem",
    lineHeight: "5rem",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    background: "#71a73b",
    "&:hover": {
      background: "#71a73b",
      filter: "brightness(90%)",
    },
  },
  toolbar: theme.mixins.toolbar,
  toolbars: {
    margin: "auto 1px",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  contenedorToolbar: {
    marginLeft: "auto!important",
    display: "flex",
  },
  lineaDivisora: {
    width: 0,
    borderRight: "1px solid #e3e6f0",
    height: "calc(5rem - 2rem)",
    margin: "auto 1rem",
  },
  contenedor: {
    padding: "10px",
    paddingTop: 30,
  },
});
